exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-archive-blog-archive-tsx": () => import("./../../../src/templates/blog-archive/blogArchive.tsx" /* webpackChunkName: "component---src-templates-blog-archive-blog-archive-tsx" */),
  "component---src-templates-contact-contact-tsx": () => import("./../../../src/templates/contact/contact.tsx" /* webpackChunkName: "component---src-templates-contact-contact-tsx" */),
  "component---src-templates-diensten-diensten-tsx": () => import("./../../../src/templates/diensten/diensten.tsx" /* webpackChunkName: "component---src-templates-diensten-diensten-tsx" */),
  "component---src-templates-front-page-front-page-tsx": () => import("./../../../src/templates/front-page/frontPage.tsx" /* webpackChunkName: "component---src-templates-front-page-front-page-tsx" */),
  "component---src-templates-over-over-tsx": () => import("./../../../src/templates/over/over.tsx" /* webpackChunkName: "component---src-templates-over-over-tsx" */),
  "component---src-templates-single-page-single-page-tsx": () => import("./../../../src/templates/single-page/singlePage.tsx" /* webpackChunkName: "component---src-templates-single-page-single-page-tsx" */),
  "component---src-templates-single-post-single-post-tsx": () => import("./../../../src/templates/single-post/singlePost.tsx" /* webpackChunkName: "component---src-templates-single-post-single-post-tsx" */)
}

